/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query wroclaw {
    file(relativePath: { eq: "parking-lotnisko-wroclaw-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Wrocław",
  tagline: "Wrocław, Polska",
  featuredImage: "/assets/parking-lotnisko-wroclaw-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> ul. Graniczna 190, 54-530 Wrocław Międzynarodowy Port
        Lotniczy im. Mikołaja Kopernika położony jest na terenie miasta
        Wrocławia, w odległości ok. 10 km. na zachód od jego centrum, na osiedlu
        Strachowice.
      </p>
    }
  >
    <h2>
      Parkingi przy lotnisku we Wrocławiu: Co warto wiedzieć przed podróżą?
    </h2>
    <p>
      Jeśli planujesz lot z lotniska we Wrocławiu, musisz wiedzieć, gdzie
      zaparkować swój samochód. Na szczęście, w pobliżu portu lotniczego
      znajdziesz wiele parkingów, które oferują różne ceny i usługi.
    </p>
    <h3>Parking przy lotnisku we Wrocławiu - Jakie są opcje?</h3>
    <p>
      Najprostszą opcją jest skorzystanie z oficjalnego parkingu lotniskowego. W
      pobliżu terminala pasażerskiego znajdują się dwa parkingi zarządzane przez
      lotnisko. W parkingu P1 znajdują się miejsca krótkoterminowe, a w P2 -
      miejsca długoterminowe. Cena za godzinę parkowania na parkingu P1 wynosi
      10 zł, a na parkingu P2 - 4 zł. W przypadku długoterminowego parkowania
      (powyżej 3 dni) opłata za dobę wynosi 55 zł na parkingu P1 i 30 zł na
      parkingu P2.
    </p>
    <p>
      Inną opcją jest skorzystanie z usług prywatnych parkingów, które znajdują
      się w pobliżu lotniska. Przeważnie oferują one niższe ceny niż oficjalny
      parking, ale warto zwrócić uwagę na opinie innych klientów oraz warunki
      oferowane przez parking.
    </p>
    <h3>Co warto wiedzieć przed rezerwacją parkingu?</h3>
    <p>
      Przed dokonaniem rezerwacji warto sprawdzić, jak długo przed wylotem
      należy ją dokonać. Niektóre parkingi wymagają potwierdzenia rezerwacji z
      wyprzedzeniem kilku dni. Warto również zwrócić uwagę na możliwość anulacji
      rezerwacji i ewentualne koszty, które poniesiesz w przypadku rezygnacji z
      parkingu.
    </p>
    <p>
      Przed dokonaniem rezerwacji warto również zwrócić uwagę na godziny
      otwarcia parkingu. Niektóre z nich są czynne tylko w określonych
      godzinach, co może wpłynąć na Twoje plany podróży.
    </p>
    <h3>Jak zaplanować czas na dojazd na lotnisko?</h3>
    <p>
      Przed wylotem warto dokładnie zaplanować czas na dojazd na lotnisko. W
      przypadku korzystania z usług prywatnych parkingów warto zwrócić uwagę na
      odległość od terminala i czas, który będzie potrzebny na dojście do
      lotniska.
    </p>
    <p>
      Jeśli planujesz podróż samochodem, warto również zwrócić uwagę na
      możliwość wystąpienia korków i utrudnień w ruchu drogowym. Dlatego warto
      sprawdzić sytuację na drogach przed wyjazdem i zaplan
    </p>
    <h3>
      <strong>
        Dlaczego warto korzystać z parkingów przy lotnisku we Wrocławiu?
      </strong>
    </h3>
    <p>
      Przede wszystkim, korzystanie z parkingu przy lotnisku we Wrocławiu jest
      bardzo wygodne. Nie musisz martwić się, gdzie zostawić swój samochód, gdy
      lecisz na wakacje lub biznes. Po prostu zostawiasz go na parkingu i
      korzystasz z usług transferu, który zapewnia Ci szybki i wygodny przejazd
      na lotnisko.
    </p>
    <p>
      Korzystanie z parkingu przy lotnisku we Wrocławiu jest również bardzo
      bezpieczne. Parkingi te są monitorowane przez całą dobę, a niektóre z nich
      oferują dodatkowe usługi zabezpieczenia, takie jak monitoring kamerami,
      ochrona fizyczna czy ubezpieczenie.
    </p>
    <p>
      Ponadto, korzystanie z parkingu przy lotnisku we Wrocławiu jest często
      tańsze niż zostawienie samochodu na lotniskowym parkingu. Możesz
      zaoszczędzić nawet kilkadziesiąt złotych dziennie, co jest bardzo
      atrakcyjne dla osób, które szukają oszczędności.
    </p>
    <h3>Czy mogę zarezerwować miejsce parkingowe z wyprzedzeniem?</h3>
    <p>
      Tak, większość parkingu przy lotnisku we Wrocławiu oferuje możliwość
      rezerwacji miejsca parkingowego z wyprzedzeniem. Dzięki temu możesz mieć
      pewność, że na pewno znajdziesz wolne miejsce na parkingu w dniu swojego
      lotu. Warto jednak pamiętać, że w sezonie letnim i w okresie świątecznym
      miejsca parkingowe mogą być wyprzedane szybciej, dlatego zaleca się
      dokonanie rezerwacji z odpowiednim wyprzedzeniem.
    </p>
    <h2>Podsumowanie</h2>
    <p>
      Parkingi przy lotnisku we Wrocławiu to wygodne i bezpieczne rozwiązanie
      dla osób podróżujących samochodem na lotnisko. Dostępne są różne rodzaje
      parkingów, w zależności od potrzeb i preferencji podróżnych. Warto
      dokładnie zapoznać się z ofertą poszczególnych parkingów, aby wybrać
      takie, które spełnią wszystkie oczekiwania. Dzięki rezerwacji miejsca
      parkingowego z wyprzedzeniem można mieć pewność, że na pewno znajdziemy
      wolne miejsce na parkingu w dniu naszego lotu.
    </p>
    <p>
      Podczas parkowania na lotnisku warto pamiętać o kilku ważnych zasadach.
      Przede wszystkim należy pozostawić pojazd na wyznaczonym miejscu i zgodnie
      z wytycznymi pracownika parkingu. Należy też pamiętać o bezpieczeństwie
      swojego pojazdu i nie pozostawiać w nim wartościowych przedmiotów.
    </p>
  </Homepage>
)

export default HomePage
